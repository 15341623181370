/*================================================
Navbar Area CSS
=================================================*/
.main-responsive-nav {
  display: none;
}

.main-navbar {
  padding: 10px 0;
}

.main-navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-navbar .navbar {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0;
}

.main-navbar .navbar .navbar-brand {
  padding: 0 70px 0 0;
}

.main-navbar .navbar ul {
  padding-left: 30px;
  list-style-type: none;
  margin-bottom: 0;
}

.main-navbar .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item a {
  color: var(--black-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.main-navbar .navbar .navbar-nav .nav-item a::after {
  content: "";
  position: absolute;
  bottom: 25px;
  right: 0;
  width: 0;
  height: 1px;
  background-color: var(--main-color);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.main-navbar .navbar .navbar-nav .nav-item a i {
  font-size: 14px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
}

.main-navbar .navbar .navbar-nav .nav-item a:hover,
.main-navbar .navbar .navbar-nav .nav-item a:focus,
.main-navbar .navbar .navbar-nav .nav-item a.active {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item a:hover::after,
.main-navbar .navbar .navbar-nav .nav-item a:focus::after,
.main-navbar .navbar .navbar-nav .nav-item a.active::after {
  width: 100%;
  right: auto;
  left: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:hover a,
.main-navbar .navbar .navbar-nav .nav-item.active a {
  color: var(--main-color);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 70px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 250px;
  display: block;
  border-radius: 8px;
  padding: 10px 0;
  margin-top: 15px;
  position: absolute;
  visibility: hidden;
  background: var(--white-color);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 10px 20px;
  position: relative;
  display: block;
  color: var(--black-color);
  font-size: var(--font-size);
  font-weight: 400;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
  content: "\f1af";
  font-family: "remixicon";
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  font-size: 15px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--main-color);
  padding-left: 38px;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::after,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::after,
.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a.active::after {
  display: none;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  right: -250px;
  left: auto;
  margin-top: 15px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -250px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -250px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  visibility: hidden;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: var(--black-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--main-color);
}

.main-navbar
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-navbar .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-navbar .navbar .others-options {
  margin-left: 30px;
}

.main-navbar .navbar .others-options .option-item {
  margin-right: 25px;
}

.main-navbar .navbar .others-options .option-item:last-child {
  margin-right: 0;
}

.main-navbar .navbar .others-options .option-item .search-box {
  position: relative;
}

.main-navbar .navbar .others-options .option-item .search-box .input-search {
  background-color: transparent;
  border: 1px solid var(--white-color);
  outline: 0;
  padding: 10px;
  color: var(--white-color);
  border-radius: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-webkit-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:-ms-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-ms-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::placeholder {
  color: transparent;
}

.main-navbar .navbar .others-options .option-item .search-box button {
  position: absolute;
  right: 0;
  top: 12px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
  border-radius: 5px;
  font-size: 18px;
  padding: 0 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.main-navbar .navbar .others-options .option-item .language-option {
  padding: 0;
}

.main-navbar .navbar .others-options .option-item .language-option button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--white-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding-left: 25px;
  padding-right: 22px;
}

.main-navbar .navbar .others-options .option-item .language-option button i {
  position: absolute;
  top: 2px;
  left: 0;
  margin-right: 5px;
  color: var(--white-color);
  font-size: 18px;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button::after {
  display: none;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button::before {
  content: "\ea4e";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--font-size);
  border-color: var(--paragraph-color);
  font-family: "remixicon";
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  .dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
  -webkit-transform: translateY(35px) !important;
  transform: translateY(35px) !important;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu {
  border-radius: 0;
  border: 0;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  padding: 0;
  margin-top: 20px;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--paragraph-color);
  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item:last-child {
  border-bottom: none;
}

.main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item
  img {
  width: 25px;
  margin-right: 10px;
}

.main-navbar .navbar .others-options .option-item .default-btn {
  background: var(--black-color);
}

.main-navbar .navbar .others-options .option-item .default-btn::before {
  background: var(--white-color);
}

.main-navbar .navbar .others-options .option-item .default-btn:hover {
  color: var(--black-color) !important;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box {
  position: relative;
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search {
  border: 1px solid #eeeeee;
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:-ms-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-ms-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::placeholder {
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus {
  border: 1px solid var(--main-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::placeholder {
  color: transparent;
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  button {
  color: var(--paragraph-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  button:hover {
  color: var(--main-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button {
  color: var(--black-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button
  i {
  color: var(--black-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button::before {
  border-color: var(--black-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn {
  background: var(--black-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn::before {
  background: var(--gradient-color);
}

.navbar-area.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn:hover {
  color: var(--white-color) !important;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box {
  position: relative;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search {
  border: 1px solid #eeeeee;
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:-ms-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::-ms-input-placeholder {
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search::placeholder {
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus {
  border: 1px solid var(--main-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  .input-search:focus::placeholder {
  color: transparent;
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  button {
  color: var(--paragraph-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .search-box
  button:hover {
  color: var(--main-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button {
  color: var(--black-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button
  i {
  color: var(--black-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .language-option
  button::before {
  border-color: var(--black-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn {
  background: var(--gradient-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn::before {
  background: var(--main-color);
}

.navbar-style-two
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn:hover {
  color: var(--white-color) !important;
}

.navbar-style-two.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn {
  background: var(--gradient-color);
}

.navbar-style-two.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn::before {
  background: var(--main-color);
}

.navbar-style-two.is-sticky
  .main-navbar
  .navbar
  .others-options
  .option-item
  .default-btn:hover {
  color: var(--white-color) !important;
}

.others-option-for-responsive {
  display: none;
}

.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 52px;
  top: -32px;
  font-weight: 400;
}

.others-option-for-responsive .dot-menu .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--black-color);
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: var(--main-color);
}

.others-option-for-responsive .container {
  position: relative;
}

.others-option-for-responsive .container .container {
  position: absolute;
  right: 10px;
  top: 10px;
  max-width: 260px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  z-index: 2;
  padding: 15px;
  border-radius: 5px;
}

.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.others-option-for-responsive .option-inner .others-options {
  margin-left: 30px;
}

.others-option-for-responsive .option-inner .others-options .option-item {
  margin-right: 25px;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item:last-child {
  margin-right: 0;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box {
  position: relative;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search {
  background-color: transparent;
  border: 1px solid var(--white-color);
  outline: 0;
  padding: 10px;
  color: var(--white-color);
  border-radius: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search::-webkit-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search:-ms-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search::-ms-input-placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search::placeholder {
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  .input-search:focus::placeholder {
  color: transparent;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .search-box
  button {
  position: absolute;
  right: 0;
  top: 12px;
  background-color: transparent;
  border: none;
  color: var(--white-color);
  border-radius: 5px;
  font-size: 18px;
  padding: 0 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option {
  padding: 0;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--white-color);
  font-size: var(--font-size);
  font-weight: 500;
  padding-left: 25px;
  padding-right: 22px;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  button
  i {
  position: absolute;
  top: 2px;
  left: 0;
  margin-right: 5px;
  color: var(--white-color);
  font-size: 18px;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  button::after {
  display: none;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  button::before {
  content: "\ea4e";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: var(--font-size);
  border-color: var(--paragraph-color);
  font-family: "remixicon";
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  .dropdown-menu[style] {
  right: 0 !important;
  left: auto !important;
  -webkit-transform: translateY(35px) !important;
  transform: translateY(35px) !important;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu {
  border-radius: 0;
  border: 0;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  padding: 0;
  margin-top: 20px;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--paragraph-color);
  font-size: 15px;
  font-weight: 400;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item:last-child {
  border-bottom: none;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .language-option
  .language-dropdown-menu
  .dropdown-item
  img {
  width: 25px;
  margin-right: 10px;
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .default-btn {
  background: var(--black-color);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .default-btn::before {
  background: var(--white-color);
}

.others-option-for-responsive
  .option-inner
  .others-options
  .option-item
  .default-btn:hover {
  color: var(--black-color) !important;
}

@media only screen and (max-width: 1199px) {
  .main-responsive-nav {
    display: block;
  }
  .main-responsive-nav .main-responsive-menu {
    position: relative;
  }
  .main-responsive-nav .main-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .main-responsive-nav
    .main-responsive-menu.mean-container
    .mean-nav
    ul
    li
    a.active {
    color: var(--main-color);
  }
  .main-responsive-nav
    .main-responsive-menu.mean-container
    .mean-nav
    ul
    li
    a
    i {
    display: none;
  }
  .main-responsive-nav
    .main-responsive-menu.mean-container
    .mean-nav
    ul
    li
    li
    a {
    font-size: 14px;
  }
  .main-responsive-nav .main-responsive-menu.mean-container .others-options {
    display: none !important;
  }
  .main-responsive-nav .main-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 300px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
  }
  .main-responsive-nav .mean-container a.meanmenu-reveal {
    padding: 8px 0 0 0;
    color: var(--black-color);
  }
  .main-responsive-nav .mean-container a.meanmenu-reveal span {
    display: block;
    background: #000;
    height: 2px;
    margin-top: -6px;
    border-radius: 5px;
    position: relative;
    top: 8px;
  }
  .main-responsive-nav .mean-container .mean-bar {
    background-color: unset;
    border-bottom: none;
  }
  .meanmenu-reveal {
    margin-top: 10px;
  }
  .main-responsive-nav .mean-container .mean-nav {
    margin-top: 60px;
  }
  .main-responsive-nav .logo {
    position: relative;
    z-index: 999;
    width: 50%;
  }
  .navbar-area {
    background-color: var(--white-color);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .main-navbar {
    display: none;
  }
  .others-option-for-responsive {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0;
  }
  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: block !important;
  }
  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item:last-child {
    margin-bottom: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box {
    position: relative;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search {
    border: 1px solid #eeeeee;
    color: var(--paragraph-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-webkit-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus {
    border: 1px solid var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus:-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button:hover {
    color: var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button
    i {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button::before {
    border-color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn {
    background: var(--black-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn::before {
    background: var(--gradient-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn:hover {
    color: var(--white-color) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .others-option-for-responsive .dot-menu {
    top: -35px;
  }
  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0;
  }
  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: block !important;
  }
  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item:last-child {
    margin-bottom: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box {
    position: relative;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search {
    border: 1px solid #eeeeee;
    color: var(--paragraph-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-webkit-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus {
    border: 1px solid var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus:-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button:hover {
    color: var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button
    i {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button::before {
    border-color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn {
    background: var(--black-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn::before {
    background: var(--gradient-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn:hover {
    color: var(--white-color) !important;
  }
  .mean-container .mean-nav ul li a.mean-expand {
    right: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .others-option-for-responsive .dot-menu {
    top: -35px;
  }
  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0;
  }
  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: block !important;
  }
  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item:last-child {
    margin-bottom: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box {
    position: relative;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search {
    border: 1px solid #eeeeee;
    color: var(--paragraph-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-webkit-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus {
    border: 1px solid var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus:-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button:hover {
    color: var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button
    i {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button::before {
    border-color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn {
    background: var(--black-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn::before {
    background: var(--gradient-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn:hover {
    color: var(--white-color) !important;
  }
  .mean-container .mean-bar {
    padding: 0 0 0;
  }
  .mean-container .mean-nav ul li a.mean-expand {
    right: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .navbar-area.is-sticky
    .others-option-for-responsive
    .dot-menu
    .inner
    .circle {
    background-color: var(--black-color);
  }
  .navbar-area.is-sticky
    .others-option-for-responsive
    .dot-menu:hover
    .inner
    .circle {
    background-color: var(--black-color);
  }
  .main-navbar .navbar .others-options {
    display: none !important;
  }
  .others-option-for-responsive {
    display: block;
  }
  .others-option-for-responsive .dot-menu {
    padding: 0 10px 0 0;
    right: -10px;
    top: -62px;
  }
  .others-option-for-responsive .dot-menu .inner .circle {
    background-color: var(--white-color);
  }
  .others-option-for-responsive .dot-menu:hover .inner .circle {
    background-color: var(--white-color);
  }
  .others-option-for-responsive .container .container {
    right: 0;
    top: 0;
  }
  .others-option-for-responsive .option-inner .others-options {
    margin-left: 0;
  }
  .others-option-for-responsive .option-inner .others-options.d-flex {
    display: block !important;
  }
  .others-option-for-responsive .option-inner .others-options .option-item {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item:last-child {
    margin-bottom: 0;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box {
    position: relative;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search {
    border: 1px solid #eeeeee;
    color: var(--paragraph-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-webkit-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::-ms-input-placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search::placeholder {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus {
    border: 1px solid var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus:-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::-ms-input-placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    .input-search:focus::placeholder {
    color: transparent;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button {
    color: var(--paragraph-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .search-box
    button:hover {
    color: var(--main-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button
    i {
    color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .language-option
    button::before {
    border-color: var(--black-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn {
    background: var(--black-color);
    width: 100%;
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn::before {
    background: var(--gradient-color);
  }
  .others-option-for-responsive
    .option-inner
    .others-options
    .option-item
    .default-btn:hover {
    color: var(--white-color) !important;
  }
  .navbar-style-two .others-option-for-responsive .dot-menu .inner .circle {
    background-color: var(--black-color);
  }
  .navbar-style-two
    .others-option-for-responsive
    .dot-menu:hover
    .inner
    .circle {
    background-color: var(--black-color);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .main-navbar .navbar .navbar-brand {
    padding: 0 50px 0 0;
  }
  .main-navbar .navbar .navbar-nav .nav-item {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1550px) {
  .main-navbar .container-fluid {
    max-width: 1900px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
/*# sourceMappingURL=navbar.css.map */
