/* TabNation.css */

/* Styles for the tab container */
.tab-container {
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  margin-top: 20px;
}

/* Styles for the tab buttons */
.tab-buttons {
  display: flex;
  justify-content: space-between;
}

.tab-btn {
  flex: 1;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  background-color: white;
  transition: border-bottom 0.3s ease;
  text-align: center;
}

.tab-btn.active {
  border-bottom: 2px solid #51ccae;
  color: #51ccae;
}

/* Styles for the tab content */
.tab-content {
  padding: 20px;
}
