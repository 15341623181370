/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  background-color: var(--black-color);
}

.single-footer-widget {
  margin-bottom: 25px;
}

.single-footer-widget .widget-logo {
  margin-bottom: 25px;
}

.single-footer-widget p {
  margin-bottom: 0;
  color: var(--white-color);
}

.single-footer-widget .widget-social {
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.single-footer-widget .widget-social li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.single-footer-widget .widget-social li:last-child {
  margin-right: 0;
}

.single-footer-widget .widget-social li a i {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: var(--white-color);
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  text-align: center;
  font-size: var(--font-size);
  color: var(--main-color);
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-footer-widget .widget-social li a i:hover {
  color: var(--white-color);
  background-color: var(--main-color);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.single-footer-widget h3 {
  font-size: 24px;
  margin-bottom: 25px;
  color: var(--white-color);
  position: relative;
  padding-bottom: 5px;
}

.single-footer-widget h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 70px;
  height: 1px;
  background-color: var(--main-color);
}

.single-footer-widget .quick-links {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .quick-links li {
  color: var(--white-color);
  font-size: var(--font-size);
  margin-bottom: 15px;
}

.single-footer-widget .quick-links li a {
  display: inline-block;
  color: var(--white-color);
  font-size: var(--font-size);
}

.single-footer-widget .quick-links li a:hover {
  color: var(--main-color);
}

.single-footer-widget .quick-links li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-information {
  padding: 0;
  margin-bottom: 0;
}

.single-footer-widget .footer-information li {
  list-style-type: none;
  margin-bottom: 15px;
  color: var(--white-color);
  position: relative;
  padding-left: 25px;
}

.single-footer-widget .footer-information li a {
  color: var(--white-color);
}

.single-footer-widget .footer-information li a:hover {
  color: var(--main-color);
}

.single-footer-widget .footer-information li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-information li i {
  font-size: 18px;
  color: var(--main-color);
  position: absolute;
  left: 0;
  top: 3px;
}

.copyright-area {
  margin-top: 75px;
  padding-bottom: 35px;
}

.copyright-area .copyright-area-content {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding-top: 30px;
  text-align: center;
}

.copyright-area .copyright-area-content p {
  font-weight: 400;
  color: var(--white-color);
}

.copyright-area .copyright-area-content p a {
  display: inline-block;
  font-weight: bold;
  color: var(--white-color);
}

.copyright-area .copyright-area-content p a:hover {
  color: var(--main-color);
}

@media only screen and (max-width: 767px) {
  .single-footer-widget.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget.ps-3 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 20px;
  }
  .single-footer-widget .quick-links li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li {
    font-size: 15px;
    padding-left: 22px;
  }
  .single-footer-widget .footer-information li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li i {
    font-size: 15px;
  }
  .copyright-area {
    margin-top: 25px;
  }
  .copyright-area .copyright-area-content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-footer-widget.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget.ps-3 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 20px;
  }
  .single-footer-widget .quick-links li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li {
    font-size: 15px;
    padding-left: 22px;
  }
  .single-footer-widget .footer-information li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li i {
    font-size: 15px;
  }
  .copyright-area {
    margin-top: 25px;
  }
  .copyright-area .copyright-area-content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-footer-widget.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget.ps-3 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 20px;
  }
  .single-footer-widget .quick-links li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li {
    font-size: 15px;
    padding-left: 22px;
  }
  .single-footer-widget .footer-information li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li i {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .single-footer-widget.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget.ps-3 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 20px;
  }
  .single-footer-widget .quick-links li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li {
    font-size: 15px;
    padding-left: 22px;
  }
  .single-footer-widget .footer-information li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-information li i {
    font-size: 15px;
  }
}
/*# sourceMappingURL=footer.css.map */
