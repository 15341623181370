/* Add your styling to make it visually appealing */
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 10px;
  z-index: 1000;
}
#container {
  max-width: 1024px;

  margin: 0 auto;
  padding: 3rem 0;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.sec__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 90%;
  margin: 40px auto;
  justify-content: center;
}

/* COOKIE CONSENT BOX */

.cookie__box {
  --clr-primary: #d21357;
  --clr-accent: #615f5f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 30px;
  max-width: 365px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  padding: 25px 25px 30px;
  transform: translateX(0px);
  background-color: whitesmoke;
}
.cookie__box.hide {
  display: none;
  transform: translateX(-500px);
}
.cookie__box > img {
  max-width: 90px;
}
.cookie__box .content {
  margin-top: 10px;
}
.cookie__box .content h2 {
  font-size: 24px;
  font-weight: 700;
}
.cookie__box .content p {
  font-size: 15px;
  margin: 5px 0 20px;
  color: var(--clr-accent);
}
.cookie__box .content .btn__group {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cookie__box .content .btn__group * {
  margin: 0 10px;
}
.cookie__box .content .btn__group .accept__btn {
  padding: 10px 20px;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid var(--clr-primary);
  color: #ffffff;
  background-color: var(--clr-primary);
}
.cookie__box .content .btn__group .accept__btn:hover {
  background-color: #ffffff;
  color: var(--clr-primary);
}
.cookie__box .content .btn__group .reject-btn {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-primary);
}
.cookie__box .content .btn__group .reject-btn:hover {
  text-decoration: underline;
}

/* For medium devices */
@media screen and (max-width: 600px) {
  .cookie__box {
    left: 10px;
    bottom: 10px;
    padding: 20px 10px;
  }
}

/* For small devices */
@media screen and (max-width: 400px) {
  .cookie__box {
    max-width: 340px;
  }
  .cookie__box .content .btn__group {
    flex-direction: column;
    gap: 15px;
  }
}

/* For extra-small devices */
@media screen and (max-width: 320px) {
  .cookie__box {
    max-width: 290px;
  }
}
