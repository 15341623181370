/*================================================
help Form Area CSS
=================================================*/
#helpForm {
  max-width: 800px;
  margin: auto;
}

#helpForm .form-group {
  margin-bottom: 25px;
}

#helpForm .form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--black-color);
  font-weight: 500;
  font-size: 15px;
}

#helpForm .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: var(--paragraph-color);
  background-color: var(--white-color);
  border: 1px solid #e5e7ec;
  -webkit-box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
  box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
  border-radius: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
}

#helpForm .form-group .form-control::-webkit-input-placeholder {
  color: var(--paragraph-color);
}

#helpForm .form-group .form-control:-ms-input-placeholder {
  color: var(--paragraph-color);
}

#helpForm .form-group .form-control::-ms-input-placeholder {
  color: var(--paragraph-color);
}

#helpForm .form-group .form-control::placeholder {
  color: var(--paragraph-color);
}

#helpForm .form-group .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

#helpForm .form-group .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

#helpForm .form-group .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

#helpForm .form-group .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

#helpForm .form-group .form-select {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: var(--paragraph-color);
  background-color: var(--white-color);
  border: 1px solid #e5e7ec;
  -webkit-box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
  box-shadow: -12px 8px 25px rgba(217, 35, 12, 0.03);
  border-radius: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
}

#helpForm .form-group textarea.form-control {
  min-height: 115px;
}

#helpForm .list-unstyled {
  padding: 0;
  color: red;
  margin-top: 5px;
  font-size: 15px;
}

#helpForm div#msgSubmit {
  margin-bottom: 0;
}

#helpForm .text-danger {
  color: #dc3545 !important;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

#helpForm .send-btn {
  margin-top: 25px;
  /* margin-left: 40%; */
}

#helpForm .send-btn .default-btn {
  border: none;
}

#helpForm .form-cookies-consent {
  margin-bottom: 0;
}

#helpForm .form-cookies-consent a {
  color: var(--main-color);
}

#helpForm .form-cookies-consent a:hover {
  color: var(--optional-color);
}

#helpForm .form-cookies-consent [type="checkbox"]:checked,
#helpForm .form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

#helpForm .form-cookies-consent [type="checkbox"]:checked + label,
#helpForm .form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  font-weight: 400;
  font-size: 15px;
}

#helpForm .form-cookies-consent [type="checkbox"]:checked + label:before,
#helpForm .form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: var(--white-color);
}

#helpForm .form-cookies-consent [type="checkbox"]:checked + label:after,
#helpForm .form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 5px;
  height: 5px;
  background: var(--main-color);
  position: absolute;
  top: 9.5px;
  left: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 30px;
}

#helpForm .form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

#helpForm .form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#helpForm .form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--main-color);
}

#helpForm .form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--main-color);
}

#helpForm .form-cookies-consent p {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 25px;
}

#helpForm .form-cookies-consent p:last-child {
  margin-right: 0;
}

.icon-img {
  height: 50px;
}
